import React from "react";
import Helmet from 'react-helmet';
import Galerie from "../components/Galerie";
import Layout from "../components/layout";
import Info from "../components/Info";


const galerie = () => {
return (
  <>    
    <Helmet>
        <title>Galerie</title>
        <meta name='description' content="Bilder der Praxis"/>
        <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
        <meta property='og:title' content="Galerie"/>
        <meta property='og:type' content='Website'/>
        <meta property='og:description' content="Bilder der Praxis"/>
        <meta property='og:image' content=''/>
        <meta property='og:locale' content='de'/><meta property='og:url' content={`https://www.physiotherapie-winthagen.de/galerie`}/>
        <link rel="canonical" href={`https://www.physiotherapie-winthagen.de/galerie`}/>
    </Helmet>  
    <Layout>
        <Galerie/>
        <Info />
    </Layout>
  </>  
  )
}

export default galerie