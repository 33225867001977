import React from 'react';
import LightGallery from 'lightgallery/react';
import MiniHeader from './MiniHeader';
import * as styles from './Galerie.module.css';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export default function Gallerie() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div>
            <MiniHeader headline={"Praxis-Galerie"}/>
            <div className="App">
                <LightGallery
                    onInit={onInit}
                    licenseKey= 'B96C245E-D2454333-AD89EC9A-B1F28E73'
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                >

                        <a href="../images/Praxis_1.jpg">
                            <img className={styles.thumbnail} alt="Ansicht der Praxis" src="../images/Praxis_1_t.jpg" />
                        </a>
                        <a href="../images/Praxis_2.jpg">
                            <img className={styles.thumbnail} alt="Außenansicht Behandlungsräume" src="../images/Praxis_2_t.jpg" />
                        </a>
                        <a href="../images/Praxis_3.jpg">
                            <img className={styles.thumbnail} alt="Ansicht der Praxis vom Wartebereich aus" src="../images/Praxis_3_t.jpg" />
                        </a>
                        <a href="../images/Praxis_4.jpg">
                            <img className={styles.thumbnail} alt="Handtücher" src="../images/Praxis_4_t.jpg" />
                        </a>
                        <a href="../images/Praxis_5.jpg">
                            <img className={styles.thumbnail} alt="Ansicht der Praxis vom Eingang aus" src="../images/Praxis_5_t.jpg" />
                        </a>
                        <a href="../images/Praxis_6.jpg">
                            <img className={styles.thumbnail} alt="Behandlungsraum" src="../images/Praxis_6_t.jpg" />
                        </a>
                        <a href="../images/Praxis_7.jpg">
                            <img className={styles.thumbnail} alt="Behandlungsraum mit Liege" src="../images/Praxis_7_t.jpg" />
                        </a>
                        <a href="../images/Praxis_8.jpg">
                            <img className={styles.thumbnail_high} alt="Behandlungsraum" src="../images/Praxis_8_t.jpg" />
                        </a>
                        <a href="../images/Praxis_9.jpg">
                            <img className={styles.thumbnail} alt="Behandlungsraum" src="../images/Praxis_9_t.jpg" />
                        </a>
                        <a href="../images/Praxis_10.jpg">
                            <img className={styles.thumbnail} alt="Behandlungsraum" src="../images/Praxis_10_t.jpg" />
                        </a>
                        <a href="../images/Praxis_11.jpg">
                            <img className={styles.thumbnail} alt="Empfang" src="../images/Praxis_11_t.jpg" />
                        </a>
                        <a href="../images/Praxis_12.jpg">
                            <img className={styles.thumbnail} alt="Impression mit Tim" src="../images/Praxis_12_t.jpg" />
                        </a>
                        
  
                </LightGallery>
                <div className={styles.clear}></div>
            </div>
        </div>
    );
}
